<template>
  <div class="home">
    <!-- 轮播图 -->
    <div class="banner">
      <el-carousel trigger="click"
                   height="400px">
        <el-carousel-item v-for="(item, index) in imgList"
                          :key="index">
          <img :src="item.image_url"
               @click="imgGood(item)"
               alt="" />
               <!-- <div class="test" style="">

               </div> -->
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 购买流程 -->
    <ul class="shopStep">
      <li v-for="(shopItem, index) in stepList"
          :key="index">
        <div class="shopSvg">
          <svg width="50px"
               height="50px"
               viewBox="0 0 16 16"
               fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  :d="pathItem.pathD"
                  v-for="(pathItem, index) in shopItem.path"
                  :key="index"></path>
          </svg>
        </div>
        <div class="shopText">
          <h1>{{shopItem.hName}}</h1>
          <p>{{shopItem.pName}}</p>
        </div>
        <div class="shopImg"
             v-if="shopItem.bool">
          <img src="../../assets/img/step2.png"
               alt="" />
        </div>
      </li>
    </ul>
    <!-- 商品展示 -->
    <div class="goodShow"
         ref="goodShow">
      <!-- 促销产品 -->
      <div class="goodRop">
        <div class="title">
          <h1>Promotional Products</h1>
        </div>
        <div class="showList">
          <div class="showBox"
               v-for="(ropItem, I) in ropList"
               :key="I"
               @click="goodFreebies(ropItem)">
            <div class="ropImg">
              <img :src="ropItem.product_image_url"
                   alt="" />
            </div>
            <div class="ropText">{{ropItem.description}}</div>
            <div class="ropPrice">
              <!-- <div>${{ropItem.deal_price}}</div> -->
              <div>
                <span v-if="ropItem.site === 'US'">$</span>
                <span v-if="ropItem.site === 'JP'">￥</span>
                <span v-if="ropItem.site === 'UK'">￡</span>
                <span v-if="ropItem.site === 'CA' || ropItem.site === 'MX' || ropItem.site === 'DE' ||ropItem.site === 'FR' || ropItem.site === 'IT' || ropItem.site === 'ES'|| ropItem.site === 'NL' ">€</span>
                {{ropItem.deal_price}}
              </div>
              <!-- <del>${{ropItem.sale_price}}</del> -->
              <del>
                <span v-if="ropItem.site === 'US'">$</span>
                <span v-if="ropItem.site === 'JP'">￥</span>
                <span v-if="ropItem.site === 'UK'">￡</span>
                <span v-if="ropItem.site === 'CA' || ropItem.site === 'MX' || ropItem.site === 'DE' ||ropItem.site === 'FR' || ropItem.site === 'IT' || ropItem.site === 'ES'|| ropItem.site === 'NL' ">€</span>
                {{ropItem.sale_price}}
              </del>
            </div>
            <!-- 购买申请 -->
            <div class="buyGood">Apply Now</div>
          </div>
        </div>
      </div>
      <div class="goodList">
        <!-- 新产品 -->
        <div class="goodList-left">
          <!-- 无积分赠品 -->
          <div class="leftBox">
            <div class="title">
              <h1>{{freebiesName}}</h1>
            </div>
            <div class="newGoodList">
              <div class="goodBox"
                   v-for="(newItem, index) in freebiesList"
                   :key="index"
                   @click="goodFreebies(newItem)">
                <!-- 商品图片 -->
                <div class="goodImg">
                  <el-image :src="newItem.product_image_url"
                            lazy></el-image>
                </div>
                <!-- 裁剪 -->
                <div class="cutBox">
                  <div class="cutBox-left"></div>
                  <div class="cutBox-xian"></div>
                  <div class="cutBox-right"></div>
                </div>
                <!-- 商品描述 -->
                <div class="goodDes">
                  {{newItem.product_title}}
                </div>
                <!-- 物流信息 -->
                <div class="goodLog">
                  <!-- <el-image :src="newItem.product_image_url"
                            lazy></el-image> -->
                  <span :class="newItem.site_class"></span>
                  <span> Fulfilled by Amazon</span>
                </div>
                <!-- 价格 -->
                <div class="goodPrice">
                  <div class="priceBox">
                    <!-- <del class="oldPrice">${{newItem.sale_price}}</del> -->
                     <del class="oldPrice">
                      <span v-if="newItem.site === 'US'">$</span>
                      <span v-if="newItem.site === 'JP'">￥</span>
                      <span v-if="newItem.site === 'UK'">￡</span>
                      <span v-if="newItem.site === 'CA' || newItem.site === 'MX' || newItem.site === 'DE' ||newItem.site === 'FR' || newItem.site === 'IT' || newItem.site === 'ES'|| newItem.site === 'NL' ">€</span>
                      {{newItem.sale_price}}
                    </del>
                    <div class="bigDis">{{newItem.discount_rate}}%</div>
                  </div>
                  <!-- <div class="newPrice">${{newItem.deal_price}}</div> -->
                  <div class="newPrice">
                    <span v-if="newItem.site === 'US'">$</span>
                    <span v-if="newItem.site === 'JP'">￥</span>
                    <span v-if="newItem.site === 'UK'">￡</span>
                    <span v-if="newItem.site === 'CA' || newItem.site === 'MX' || newItem.site === 'DE' ||newItem.site === 'FR' || newItem.site === 'IT' || newItem.site === 'ES'|| newItem.site === 'NL' ">€</span>
                    {{newItem.deal_price}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 有积分赠品 -->
          <div class="leftBox">
            <div class="title">
              <h1>{{earnpointsName}}</h1>
            </div>
            <div class="newGoodList">
              <div class="goodBox"
                   v-for="(newItem, index) in earnpointsList"
                   :key="index"
                   @click="goodFreebies(newItem)">
                <!-- 商品图片 -->
                <div class="goodImg">
                  <el-image :src="newItem.product_image_url"
                            lazy></el-image>
                </div>
                <!-- 裁剪 -->
                <div class="cutBox">
                  <div class="cutBox-left"></div>
                  <div class="cutBox-xian"></div>
                  <div class="cutBox-right"></div>
                </div>
                <!-- 商品描述 -->
                <div class="goodDes">
                  {{newItem.product_title}}
                </div>
                <!-- 物流信息 -->
                <div class="goodLog">
                  <!-- <el-image :src="newItem.product_image_url"
                            lazy></el-image> -->
                  <span :class="newItem.site_class"></span>
                  <span> Fulfilled by Amazon</span>
                </div>
                <!-- 价格 -->
                <div class="goodPrice">
                  <div class="priceBox">
                    <!-- <del class="oldPrice">${{newItem.sale_price}}</del> -->
                    <del class="oldPrice">
                      <span v-if="newItem.site === 'US'">$</span>
                      <span v-if="newItem.site === 'JP'">￥</span>
                      <span v-if="newItem.site === 'UK'">￡</span>
                      <span v-if="newItem.site === 'CA' || newItem.site === 'MX' || newItem.site === 'DE' ||newItem.site === 'FR' || newItem.site === 'IT' || newItem.site === 'ES'|| newItem.site === 'NL' ">€</span>
                      {{newItem.sale_price}}
                    </del>
                    <div class="bigDis">{{newItem.discount_rate}}%</div>
                  </div>
                  <!-- <div class="newPrice">${{newItem.deal_price}}</div> -->
                  <div class="newPrice">
                    <span v-if="newItem.site === 'US'">$</span>
                    <span v-if="newItem.site === 'JP'">￥</span>
                    <span v-if="newItem.site === 'UK'">￡</span>
                    <span v-if="newItem.site === 'CA' || newItem.site === 'MX' || newItem.site === 'DE' ||newItem.site === 'FR' || newItem.site === 'IT' || newItem.site === 'ES'|| newItem.site === 'NL' ">€</span>
                    {{newItem.deal_price}}
                  </div>
                </div>
                <!-- 积分 -->
                <div class="getPoint">Earn {{newItem.reward_integral}} Points</div>
              </div>
            </div>
          </div>
          <!-- 折扣产品 -->
          <div class="leftBox">
            <div class="title">
              <h1>{{dealName}}</h1>
            </div>
            <div class="newGoodList">
              <div class="goodBox"
                   v-for="(dealItem, index) in dealList"
                   :key="index"
                   @click="goodDeatail(dealItem)">
                <!-- 商品图片 -->
                <div class="goodImg">
                  <el-image :src="dealItem.product_image_url"
                            lazy></el-image>
                </div>
                <!-- 裁剪 -->
                <div class="cutBox">
                  <div class="cutBox-left"></div>
                  <div class="cutBox-xian"></div>
                  <div class="cutBox-right"></div>
                </div>
                <!-- 商品描述 -->
                <div class="goodDes">
                  {{dealItem.product_title}}
                </div>
                <!-- 物流信息 -->
                <div class="goodLog">
                  <!-- <el-image :src="dealItem.product_image_url"
                            lazy></el-image> -->
                  <span :class="dealItem.site_class"></span>
                  <span> Fulfilled by Amazon</span>
                </div>
                <!-- 价格 -->
                <div class="goodPrice">
                  <div class="priceBox">
                    <!-- <del class="oldPrice">${{dealItem.sale_price}}</del> -->
                    <del class="oldPrice">
                      <span v-if="dealItem.site === 'US'">$</span>
                      <span v-if="dealItem.site === 'JP'">￥</span>
                      <span v-if="dealItem.site === 'UK'">￡</span>
                      <span v-if="dealItem.site === 'CA' || dealItem.site === 'MX' || dealItem.site === 'DE' ||dealItem.site === 'FR' || dealItem.site === 'IT' || dealItem.site === 'ES'|| dealItem.site === 'NL' ">€</span>
                      {{dealItem.sale_price}}
                    </del>
                    <div class="bigDis">{{dealItem.discount_rate}}%</div>
                  </div>
                  <!-- <div class="newPrice">${{dealItem.deal_price}}</div> -->
                  <div class="newPrice">
                    <span v-if="dealItem.site === 'US'">$</span>
                    <span v-if="dealItem.site === 'JP'">￥</span>
                    <span v-if="dealItem.site === 'UK'">￡</span>
                    <span v-if="dealItem.site === 'CA' || dealItem.site === 'MX' || dealItem.site === 'DE' ||dealItem.site === 'FR' || dealItem.site === 'IT' || dealItem.site === 'ES'|| dealItem.site === 'NL' ">€</span>
                    {{dealItem.deal_price}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import '../../assets/less/home.css'
// 引入接口
import {
  bannerImg, // 轮播图
  newProducts, // 热销商品
  newFreebies, // 无积分赠品
  earnPoints, // 有积分赠品
  deatilSearch // 折扣商品
} from '../../api/buyerFront'
// 引入token
import {
  getToken // 获取token
} from '../../utils/token'
import { returnFloat } from '../../utils/num'
export default {
  data () {
    return {
      // 轮播图片
      imgList: [],
      // 流程列表
      stepList: [
        {
          bool: true,
          hName: 'AUDIT',
          pName: 'Click the Review Request button and Waiting for audit',
          path: [
            {
              pathD:
                'M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z'
            },
            {
              pathD: 'M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z'
            },
            {
              pathD:
                'M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z'
            }
          ]
        },
        {
          bool: true,
          hName: 'CONFIRMATION',
          pName: 'Get seller confirmation and buy on Amazon within 7 days',
          path: [
            {
              pathD:
                'M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z'
            }
          ]
        },
        {
          bool: true,
          hName: 'APPROVED',
          pName: 'Fill in Amazon order info and approved by the seller',
          path: [
            {
              pathD:
                'M14.5 3h-13a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z'
            },
            {
              pathD:
                'M7 5.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0zM7 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm-1.496-.854a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0z'
            }
          ]
        },
        {
          bool: false,
          hName: 'CASHBACK',
          pName: 'Get the refund to your PayPal account',
          path: [
            {
              pathD:
                'M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z'
            },
            {
              pathD:
                'M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z'
            }
          ]
        }
      ],
      // 热销列表
      ropList: [],
      // 左边盒子
      leftGoods: [
        // 新赠品
        {
          name: 'The New Freebies',
          newName: []
        },
        // 赚取积分
        {
          name: 'Earn Points',
          newName: []
        }
      ],
      // 无积分赠品
      freebiesName: 'The New Freebies',
      freebiesList: [],
      // 有积分赠品
      earnpointsName: 'Earn Points',
      earnpointsList: [],
      // 新折扣
      dealName: 'The New Deals',
      dealList: []
    }
  },
  created () {
    // 调用vuex获取个人信息
    this.$store.commit('getUser')
    // 获取轮播图图片
    bannerImg(this.$store.state.countrySplit).then(result => {
      if (result.data.code === 200) {
        let i = 0
        while (result.data.data.length < 3) {
          result.data.data.push({
            id: i,
            image_url: require('../../assets/img/banner2.jpg'),
            product_url: ''
          })
          i++
        }
        this.imgList = result.data.data
      }
    }).catch(err => {
      return err
    })
    // 热销商品
    newProducts({
      page: '1', // 页数
      size: '6', // 显示数量
      site: this.$store.state.countrySplit // vuex存储的国家值
    }).then(result => {
      // 接口调用成功 状态码为200
      if (result.data.code === 200) {
        this.ropList = result.data.data.rows // 热销列表获取数据
        this.ropList.deal_price = returnFloat(this.ropList.deal_price)
        this.ropList.sale_price = returnFloat(this.ropList.sale_price)
      }
      // //('result1 =>', result)
    }).catch(err => {
      return err
    })
    // 无积分赠品
    newFreebies({
      page: '1', // 页数
      size: '30', // 显示数量
      site: this.$store.state.countrySplit // vuex存储的国家值
    }).then(result => {
      // 接口调用成功 状态码为200
      if (result.data.code === 200) {
        this.freebiesList = result.data.data.rows // 无积分赠品列表获取数据
        this.freebiesList.map(v => {
          v.deal_price = returnFloat(v.deal_price)
          v.sale_price = returnFloat(v.sale_price)
        })
      }
      // //('result2 =>', result)
    }).catch(err => {
      return err
    })
    // 有积分赠品
    earnPoints({
      page: '1', // 页数
      size: '30', // 显示数量
      site: this.$store.state.countrySplit // vuex存储的国家值
    }).then(result => {
      // 接口调用成功 状态码为200
      if (result.data.code === 200) {
        this.earnpointsList = result.data.data.rows // 有积分赠品列表获取数据
        this.earnpointsList.map(v => {
          v.deal_price = returnFloat(v.deal_price)
          v.sale_price = returnFloat(v.sale_price)
        })
        // //('result3 =>', result)
      }
    }).catch(err => {
      return err
    })
    // 折扣
    deatilSearch({
      categoryId: '',
      discountHigherLimit: '',
      discountLowerLimit: '',
      page: '1', // 页数
      priceHigherLimit: '',
      priceLowerLimit: '',
      site: this.$store.state.countrySplit, // vuex存储的国家值
      size: '30', // 显示数量
      sorting: ''
    }).then(result => {
      // 接口调用成功 状态码为200
      if (result.data.code === 200) {
        this.dealList = result.data.data.rows // 无积分赠品列表获取数据
        // (this.dealList)
        const goodTime = (new Date()).getTime() // 获取当前时间戳
        this.dealList.map(v => {
          v.product_image_url = JSON.parse(v.product_image_url)[0]
          v.deal_price = returnFloat(v.deal_price)
          v.sale_price = returnFloat(v.sale_price)
        })
      }
    }).catch(err => {
      return err
    })
  },
  methods: {
    imgGood (item) {
      if (item.product_url) {
        this.$router.push({ path: '/F_freeDetails', query: { goodUrl: item.product_url, goodId: item.product_id } })
      }
    },
    // 赠品跳转详情
    goodFreebies (item) {
      this.$router.push({ path: '/F_freeDetails', query: { goodUrl: item.url, goodId: item.product_id } })
    },
    // 折扣跳转详情
    goodDeatail (item) {
      this.$router.push({ path: '/F_dealDetails', query: { goodUrl: item.url, goodId: item.product_id } })
    }
  }
}
</script>
<style lang="less" scoped>
.home {
    padding-top: 20px;
    margin: 0 20px;
    text-align: left;

    //轮播图
    .banner {
        .el-carousel__item {
            img {
                cursor: pointer;
                width: 100%;
                height: 400px;
                // object-position: top;
            }
        }

         .el-carousel__indicator button {
            height: 10px;
            width: 10px;
            border-radius: 50%;
        }
    }

    //购买流程
    .shopStep {
        background: #ff9933;
        display: flex;
        justify-content: space-between;
        padding: 30px 10px;
        margin: 15px 0;

        li {
            padding-right: 30px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .shopSvg {
                svg {
                    color: white;
                }
            }

            .shopText {
                color: white;
                margin-left: 20px;
                font-size: 16px;
                line-height: 1.5;

                h1 {
                    font-size: 20px;
                    font-weight: normal;
                }

                p {
                    width: 80%;
                    font-size: 14px;
                }
            }

            .shopImg {
                margin-left: 10px;
            }
        }
    }

    //商品展示
    .goodShow {

        // 促销商品
        .goodRop {
            width: 100%;

            .title {
                border-bottom: 1px solid #cccccc;
                margin-bottom: 40px;

                h1 {
                    display: inline-block;
                    text-align: left;
                    font-size: 24px;
                    border-bottom: 3px solid #ff9933;
                    padding: 0 0 10px 0;
                    // border-bottom: 1px solid #cccccc;
                }
            }

            .showList {
                display: flex;
                justify-content: space-between;
                &::after {
                    content: '';
                    flex: auto;
                }
                .showBox {
                    margin-right: 30px;
                    cursor: pointer;
                    width: 12%;
                    background: white;
                    padding: 20px;
                    border-radius: 20px;
                    border: 1px solid #cccccc;

                    // box-sizing: border-box;
                    .ropImg {
                        // width: 100%;
                        height: 180px;
                        display: flex;
                        justify-content: center;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .ropText {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 18px;
                        padding: 10px 0;
                    }

                    .ropPrice {
                        display: flex;
                        justify-content: space-between;
                        margin: 10px 0;
                        padding: 0 20px;
                        font-weight: bolder;
                        color: #ff9933;
                        font-size: 16px;

                        del {
                            color: #999999;
                            font-weight: normal;
                        }
                    }

                    .buyGood {
                        font-size: 16px;
                        color: white;
                        font-weight: 700;
                        width: 100%;
                        text-align: center;
                        background-color: #ff9933;
                        // width: calc(100% - 40);s
                        padding: 5px 0;
                        border-radius: 5px;
                        // transform: translateY(50px);
                        transition: all 0.5s;
                        opacity: 0;
                    }

                    &:hover {
                        .buyGood {
                            // transform: translateY(0);
                            opacity: 1;
                        }
                    }
                }
            }
        }

        // 新商品
        .goodList {
            margin-top: 40px;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .goodList-left {
                // width: 75%;
                width: 100%;
                margin-right: 20px;

                .title {
                    border-bottom: 1px solid #cccccc;
                    margin-bottom: 40px;

                    h1 {
                        display: inline-block;
                        text-align: left;
                        font-size: 24px;
                        border-bottom: 3px solid #ff9933;
                        padding: 0 0 10px 0;
                        // border-bottom: 1px solid #cccccc;
                    }
                }

                .newGoodList {
                    display: flex;
                    justify-content:flex-start;
                    flex-wrap: wrap;

                    &::after {
                        content: '';
                        flex: auto;
                    }

                    .goodBox {
                        margin-right: 2%;
                        cursor: pointer;
                        width: 15%;
                        background: white;
                        padding: 15px;
                        box-sizing: border-box;
                        margin-bottom: 20px;

                        &:nth-child(6n) {
                            margin-right: 0;
                        }

                        // position: relative;
                        .cutBox {
                            // position: absolute;
                            // left: 0;
                            // top: 165px;
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .cutBox-left {
                                width: 30px;
                                height: 30px;
                                background: #f2f2f2;
                                border-radius: 50%;
                                transform: translateX(-25px);
                            }

                            .cutBox-xian {
                                border: 2px dashed #f2f2f2;
                                width: 70%;
                                height: 0;
                            }

                            .cutBox-right {
                                width: 30px;
                                height: 30px;
                                background: #f2f2f2;
                                border-radius: 50%;
                                transform: translateX(25px);
                            }
                        }

                        .goodImg {
                            height: 150px;

                            .el-image {
                                margin: auto;
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .goodDes {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-bottom: 20px;
                        }

                        .goodLog {
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            img {
                                width: 16px;
                                height: auto;
                            }

                            span {
                                font-size: 14px;
                                color: #cccccc;
                            }
                        }

                        .goodPrice {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .priceBox {
                                width: 70%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .oldPrice {
                                    font-size: 10%;
                                    font-weight: bolder;
                                }

                                .bigDis {
                                    font-size: 10px;
                                    color: white;
                                    font-weight: 700;
                                    background-color: #ff9933;
                                    padding: 5px 10px;
                                    border-radius: 5px;
                                    margin-left: 5px;
                                }
                            }

                            .newPrice {
                                font-weight: 700;
                                color: #ff9933;
                            }

                        }

                        .getPoint {
                            margin: 10px auto;
                            width: 80%;
                            background-color: #f8ac59;
                            color: white;
                            border-radius: 20px;
                            text-align: center;
                            font-size: 12px;
                            line-height: 20px;
                        }
                    }
                }
            }

            .scollRight {
                position: fixed;
                top: 50px;
                right: 10px;
                z-index: 999;
            }

            .goodList-right {
                width: calc(25% - 20px);
                margin-top: 35px;
                background-color: white;
                padding: 15px 0;

                // height: calc(100vh - 150px);
                // overflow: hidden;
                h1 {
                    font-size: 20px;
                    color: #ff9933;
                    border-left: 5px solid #ff9933;
                    padding: 0 15px;
                    margin: 0 15px 25px 15px;
                }

                .scollRightBox {
                    height: calc(100vh - 200px);
                    overflow-y: auto;
                }

                .rightBox {

                    // height: calc(100vh - 200px);
                    // overflow-y: auto;
                    // padding-bottom: 20px;
                    .hotBox {
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        border-bottom: 1px solid #f2f2f2;

                        .hotImg {
                            height: 104px;
                            width: 104px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }

                        .hotText {
                            font-size: 16px;
                            width: 60%;
                            display: flex;
                            align-items: flex-end;
                            flex-wrap: wrap;

                            p {
                                width: 100%;
                            }

                            .hotPrice {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;

                                .hotPrice-left {
                                    color: #ce0000;
                                }

                                .hotPrice-right {
                                    span {
                                        font-size: 14px;
                                        color: #ce0000;
                                        background-color: #f7c617;
                                        padding: 4px 5px;
                                        border-radius: 5px;
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
